import { $Node, $svg, attr } from "@aelea/dom"


export interface IIcon {
  width?: number // in pixels
  height?: number // in pixels
  $content: $Node
  viewBox?: string
  fill?: string
}
const $path = $svg('path')


export const $logo = $svg('g')(
  $path(attr({ d: 'M6.981 23.773l-.776 1.288h5.273l.764-1.288h-5.26zm2.148-3.547l-.777 1.283h5.238l.758-1.283h-5.22zm0 0l-.777 1.283h5.238l.758-1.283h-5.22zm-2.924 4.835h5.273l.764-1.288h-5.26l-.777 1.288zm24.472.434L18.136 4.117c-.2-.342-.49-.625-.839-.819A2.356 2.356 0 0016.191 3h-.03c-.391.001-.776.1-1.118.287h-.002l-.002.001c-.342.189-.629.46-.833.789l-.005.008-.005.008L1.34 25.348l-.005.01-.006.009c-.21.36-.323.767-.33 1.183v.02c0 .416.11.83.318 1.196l.007.013.008.012c.215.361.524.664.895.876h.001l.001.002c.382.217.815.33 1.255.331h25.13c.41 0 .815-.103 1.174-.299l.006-.003.006-.003c.368-.204.672-.502.882-.862l.19-.327v-.104a2.257 2.257 0 00-.196-1.907zM15.458 4.815a.823.823 0 01.707-.39c.142.003.28.04.404.11a.773.773 0 01.295.288l.71 1.21-4.8 7.942h6.596l-1.101-1.873-.293.626h-2.901l3.247-5.365 5.263 9.014h-15.1l6.973-11.562zM3.485 27.575a1.026 1.026 0 01-.887-.499 1.022 1.022 0 01-.134-.506 1.02 1.02 0 01.139-.499l5.055-8.363h11.168l-5.862 9.866H3.485zm25.925-.466v.02a.887.887 0 01-.335.328.966.966 0 01-.46.117H14.508l5.865-9.866h3.999l2.435 4.115h-3.033l-.92-1.524h-1.048l-2.765 4.689h6.587l-1.08-1.86h3.048l1.813 3.081a.866.866 0 010 .9zM9.129 20.226l-.777 1.283h5.238l.758-1.283h-5.22zm-2.924 4.835h5.273l.764-1.288h-5.26l-.777 1.288z', }))(),
)
export const $logoFull = $path(attr({ 'clip-rule': 'evenodd', 'fill-rule': 'evenodd', d: 'M30.86 28.475v.023a.988.988 0 01-.37.368 1.06 1.06 0 01-.511.131H14.348l6.499-11.08h4.431l2.7 4.622h-3.362l-1.02-1.712h-1.161l-3.064 5.265h7.3l-1.198-2.089h3.378l2.01 3.462a.983.983 0 010 1.01z M15.4 3.438A.917.917 0 0116.182 3c.156.003.31.045.448.123a.862.862 0 01.326.323l.788 1.359-5.32 8.92h7.31l-1.221-2.104-.324.703h-3.215L18.573 6.3l5.832 10.123H7.673L15.4 3.438z M2.132 28.997a1.142 1.142 0 01-.983-.56A1.16 1.16 0 011 27.87c.003-.198.056-.39.154-.56l5.602-9.393h12.376l-6.497 11.08H2.132zm3.014-2.823l.86-1.445h5.83l-.847 1.445H5.146zm2.379-3.988l.86-1.441h5.784l-.84 1.44H7.525z' }))()

export const $gmxLogo = $path(
  attr({
    d: 'M1.163 26.401c-.162.194-.212.437-.11.522.082.068 20.794.11 20.897.043.058-.039.02-.194-.074-.31a.39.39 0 01-.076-.122c0-.015-.025-.053-.054-.085a7.702 7.702 0 01-.394-.59 5.145 5.145 0 00-.264-.394.633.633 0 01-.072-.122c-.028-.058-.06-.106-.073-.106l-4.349-6.65a.093.093 0 01-.039-.066c0-.023-.024-.07-.053-.105a3.373 3.373 0 01-.174-.25c-.159-.248-.213-.246-.374.018-.068.111-.148.23-.177.265-.03.035-.054.074-.054.087 0 .014-.018.044-.039.067a3.883 3.883 0 00-.185.271l-2.164 3.304a.61.61 0 00-.082.138c-.022.05-.048.09-.058.09-.01 0-.046.046-.077.103-.136.242 0 .222-1.536.222H10.2l.132-.198.193-.289 5.014-7.644c.076-.113.172-.261.214-.33.39-.625.421-.643.604-.343.033.054.07.112.083.13.013.018.083.128.154.244l1.65 2.525c.044.052.376.558.594.907l4.951 7.564a95.647 95.647 0 00.884 1.356c.047.072.12.174.16.228l.077.098 2.863.01c3.65.012 3.428.056 2.989-.596l-.256-.39L16.685 4.857c-.124-.204-.355-.56-.396-.61l-.112-.139c-.077-.096-.19-.133-.287-.093L1.162 26.401z',
  })
)()

export const $labLogo = $path(
  attr({
    d: 'M22.16 31H10.594c-6.311 0-7.531-3.776-5.513-8.373l2.169-4.88a29.557 29.557 0 019.127 1.313 11.168 11.168 0 008.78-1.328l.287-.18 2.244 5.09C29.706 27.24 28.38 31 22.16 31zM21.558 8.224h-.226l3.208 7.253-.617.388A8.979 8.979 0 0117.1 16.94a31.172 31.172 0 00-8.841-1.492l3.223-7.298h-.452a3.657 3.657 0 01-1.282-.636 3.621 3.621 0 01-.935-1.076 3.589 3.589 0 01-.329-2.766c.17-.618.491-1.185.935-1.651A3.935 3.935 0 0111.03 1h10.71a3.597 3.597 0 012.53 1.045 3.447 3.447 0 01.949 3.358 3.653 3.653 0 01-1.328 2.05 3.712 3.712 0 01-2.333.77z',
  })
)()

export const $walletConnectLogo = $path(
  attr({
    d: 'M7.142 9.778c4.892-5.037 12.824-5.037 17.716 0l.59.606a.659.659 0 010 .912l-2.015 2.074a.307.307 0 01-.443 0l-.81-.834a8.563 8.563 0 00-12.36 0l-.868.894a.307.307 0 01-.442 0l-2.015-2.074a.659.659 0 010-.912l.647-.666zm21.882 4.29l1.793 1.845a.658.658 0 010 .912l-8.084 8.323a.614.614 0 01-.885 0l-5.737-5.907a.153.153 0 00-.222 0l-5.736 5.907a.614.614 0 01-.886 0l-8.084-8.323a.659.659 0 010-.912l1.793-1.846a.614.614 0 01.886 0l5.737 5.907c.061.063.16.063.221 0l5.737-5.907a.614.614 0 01.886 0l5.737 5.907c.06.063.16.063.221 0l5.737-5.907a.614.614 0 01.886 0z',
  })
)()



export const $bagOfCoinsCircle = $path(attr({ 'clip-rule': 'evenodd', 'fill-rule': 'evenodd', d: 'M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16zm-.077-11.855v1.629c-5.194 0-6.468-.395-6.468-2.444 0-3.27 3.283-7.331 6.468-7.331 1.851 0 3.734 1.291 5.04 3.258h-3.424c-.893 0-1.616.73-1.616 1.63v1.628c0 .297.078.575.216.815a1.63 1.63 0 00-.216.815zm6.467 1.629h-4.85v-1.63h4.85v1.63zm-6.467 1.629h6.467c.893 0 1.617-.73 1.617-1.63v-1.628a1.63 1.63 0 00-.216-.815 1.63 1.63 0 00.216-.815v-1.629c0-.723-.468-1.336-1.115-1.549-1.054-1.989-2.614-3.575-4.385-4.383.63-.635 1.101-1.55 1.405-2.72.012-.056.018-.084.022-.112a.893.893 0 00-.883-1.01h-6.258c-.057.002-.085.003-.114.006a.897.897 0 00-.746 1.119c.306 1.176.782 2.095 1.418 2.73-3.072 1.422-5.513 5.126-5.513 8.363 0 1.903.948 3.089 2.693 3.63 1.213.375 2.367.443 5.392.443zm6.467-6.517v1.63h-4.85v-1.63h4.85zM18.044 8.74c-.476 1.127-1.17 1.63-2.121 1.63-.952 0-1.646-.503-2.122-1.63h4.243z' }))()
export const $fileCheckCircle = $path(attr({ 'clip-rule': 'evenodd', 'fill-rule': 'evenodd', d: 'M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16zm-6.276-3.616a.713.713 0 00-.386-.386l-12.191-5.05a.713.713 0 00-.932.386l-5.05 12.192a.712.712 0 00.386.931l8.187 3.39a.715.715 0 00.545 0l5.663-2.345a.713.713 0 00.386-.386l3.391-8.186a.713.713 0 000-.546zm-8.396 9.488l.727-1.756 1.756.727-2.483 1.029zm3.96-1.96l-3.346-1.386a.713.713 0 00-.931.386l-1.386 3.346-6.87-2.846L13.26 8.54l10.873 4.504-2.845 6.868zM19.6 14.923a.5.5 0 01-.27.654l-4.045 1.676a.5.5 0 01-.654-.271l-.783-1.89a.5.5 0 01.27-.653l.394-.163a.5.5 0 01.654.27l.237.573a.5.5 0 00.653.27l2.727-1.13a.5.5 0 01.654.27l.163.394z' }))()
